<template>
    <recess-card
        variant="variant1"
        class="px-5 my-3"
    >
        <div class="row">
            <!-- Subtitle -->
            <div class="col-12">
                <subtitle-text
                    :subtitle="PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.Title"
                    qa-class="qa-edit-content"
                />
            </div>
        </div>

        <div class="row" v-if="contentData.content">
            <div class="col-12 mb-4 qa-course-content-intro">
                <h5 class="mb-3">
                    {{ PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.IntroductionBlockTitle }}*
                </h5>
                <recess-rich-text-editor
                    id="introHtml"
                    v-model="contentData.content[0].introHtml" 
                    :editorToolbar="textEditorSettings"
                    @inputText="introChange"
                    @input="introHtmlChange"
                />
                <p
                    v-if="!$v.contentItems.introHtml.required"
                    class="u-error-text"
                >{{ errorMessage('Introductietekst') }}</p>
            </div>

            <div class="col-12 qa-course-content-definition">
                <h5 class="mb-3">
                    {{ PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.DefinitionBlockTitle }}*
                </h5>
                <recess-rich-text-editor
                    id="descriptionHtml"
                    v-model="contentData.content[0].descriptionHtml" 
                    :editorToolbar="textEditorSettings"
                    @inputText="descriptionChange"
                    @input="descriptionHtmlChange"
                />
                <p
                    v-if="!$v.contentItems.descriptionHtml.required"
                    class="u-error-text"
                >{{ errorMessage('Omschrijvingtekst') }}</p>
            </div>

            <!-- content block -->
            <div v-if="contentBlocks"
                class="col-12"
            >
                <h5 v-if="hasContentBlock"
                    class="mt-4"
                >
                    {{ PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.TextBlock.Title }}
                </h5>
                <div v-for="(block, index) in contentData.content[0].blocks"
                    :key="`contentBlock-${index}`"
                >

                    <!-- content block heading -->
                    <div class="row">
                        <recess-input
                            :value="block.heading"
                            :label-text="PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.TextBlock.Heading.Label"
                            class="col-6 qa-course-content-block-title"
                            :error-message="headingError(index)"
                            @input="(value) => setBlockContent('heading', value, index)"
                            @blur="$v.contentBlocks.$each[index].heading.$touch()"
                        />

                        <div class="col-6 qa-course-content-block-type">
                            <recess-multi-select
                                :selected-options="block.type"
                                :options="courseContentBlockTypes"
                                :search-input="true"
                                :single-select="true"
                                :label-text="PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.TextBlock.Type.Label"
                                :placeholder="PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.TextBlock.Type.Placeholder"
                                :error-message="typeError(index)"
                                @input="(value) => setBlockContent('type', value, index)"
                                @blur="$v.contentBlocks.$each[index].type.$touch()"
                            />
                        </div>
                    </div>
                    <!-- content block type -->

                    <div class="row">
                        <div class="col-12 qa-course-content-body-html">
                            <!-- content block bodyHtml -->
                            <div class="c-recess-input__label qa-recess-input__label">
                                {{ PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.TextBlock.BodyHtmlTitle }}
                            </div>
                            <recess-rich-text-editor
                                :id="`block-${index}`"
                                v-model="block.bodyHtml" 
                                :editorToolbar="textEditorSettings"
                                @inputText="(value) => setBlockBodyContent(index, value)"
                                @input="(value) => setBlockBodyHtmlContent(index, value)"
                            />
                            <span class="u-error-text">{{ bodyHtmlError(index) }}</span>
                        </div>
                    </div>
                    <!-- remove content block button -->
                    <div v-if="hasContentBlock" class="d-flex justify-content-end">
                        <button-component
                            type="button"
                            :back-label="PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.RemoveContentBlockButton"
                            icon-font="delete_outline"
                            :button-class="`c-button--variant3 mb-auto qa-remove-content-block-button-${index}`"
                            @click.native="removeContentBlock(index)"
                        />
                    </div>

                </div>
            </div>
        </div>

        <div class="row mt-5">
            <div class="col-12 d-flex justify-content-end">
                <recess-button
                    variant="tertiary"
                    :title="PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.OpenPreviewPopupButton"
                    class="qa-preview-inhoud"
                    @click.prevent.native="openInhoudPreviewPopup()"
                />

                <button-component
                    type="button"
                    :back-label="PROVIDER.Portfolio.CourseEdit.ContentBlocksTab.AddContentBlockButton"
                    icon-font="add"
                    button-class="c-button--variant2 ml-3 qa-add-content-button"
                    @click.native="addContentBlockHandler"
                />

            </div>

            <div class="col-12">
                <recess-divider show-line />
            </div>

            <!-- Divider, notification & submit button -->
            <div class="col-12 d-flex justify-content-end">
                <recess-button
                    v-if="!isOnCourseEditPage"
                    variant="secondary"

                    :title="BUTTON_TEXT.previousTab"
                    class="qa-previous-tab-button mr-3"
                    @click.prevent.native="handleClickOnTab(null, 'previous-tab')"
                />

                <recess-button
                    variant="secondary"
                    :title="!isOnCourseEditPage ? BUTTON_TEXT.nextTab : BUTTON_TEXT.save"
                    class="qa-edit-course-submit-button"
                    @click.prevent.native="tabButtonAction()"
                />
            </div>
        </div>

        <InhoudPreviewPopup
            :is-modal-visible="isInhoudPreviewPopupVisible"
            :preview-content-blocks="setPreviewContentBlocks"
            @close="closeInhoudPreviewPopup"
        />

    </recess-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { getEnumList } from '@/../../shared/api/SharedClient'
import { isRequiredErrorMessage, requiredError } from '@/../../shared/constants/validationMessageHelper.js'

import { API_CALL_URL_PATHS, BUTTON_TEXT } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

import { addItem } from '../../../utils/vuexFunctionHelper'

import tabMixin from '../../../mixins/tabNavigationMixin'
import { addNewContentBlockError, missingMandatoryFieldsError } from '../../../../../shared/constants/validationMessageHelper'

const SubtitleText = () => import('@/components/atoms/SubtitleText')
const StaticAlert = () => import('@/components/atoms/StaticAlert')
const ButtonComponent = () => import('@/components/atoms/ButtonComponent')
const InhoudPreviewPopup = () => import('@/components/molecules/InhoudPreviewPopup')


export default {
    components: {
        SubtitleText,
        ButtonComponent,
        StaticAlert,
        InhoudPreviewPopup
    },
    mixins: [validationMixin, tabMixin],
    inject: ['contentCourseData'],
    validations: {
        contentBlocks: {
            $each: {
                heading: { required },
                type: { required },
                bodyHtml: { required }
            }
        },
        contentItems: {
            introHtml: { required },
            descriptionHtml: { required }
        }
    },
    data() {
        return {
            PROVIDER,
            BUTTON_TEXT,
            textEditorSettings: [
                ["bold"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],
            contentData: {
                blocks: [],
                description: null,
                descriptionHtml: null,
                intro: null,
                introHtml: null,
                source: null
            },
            courseContentBlockTypes: [],
            isInhoudPreviewPopupVisible: false,
            setPreviewContentBlocks: []
        }
    },
    computed: {
        isOnCourseCreatePage() {
            return this.$route.name === 'course-create'
        },
        contentItems() {
            return ({
                introHtml: this.contentData.content[0].introHtml,
                descriptionHtml: this.contentData.content[0].descriptionHtml
            })
        },
        contentBlocks() {
            return this.contentData?.content[0]?.blocks
        },
        hasContentBlock() {
            return this.contentBlocks && this.contentBlocks.length > 0
        }
    },
    watch: {
        contentCourseData: {
            handler() {
                if (this.contentCourseData) {
                    this.contentData = JSON.parse(JSON.stringify(this.contentCourseData.contentData))
                }
            },
            immediate: true,
            deep: true
        },
        contentData: {
            handler() {
                this.$emit('triggerProgressChange', this.contentData)
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        // Code that will run only after the entire view has been rendered
        // We need this otherwise the token will not be sent in the request header through axios
        this.$nextTick(() => {
            this.getCourseContentBlockTypeEnum()
        })
    },
    methods: {
        errorMessage(field) {
            return requiredError(field)
        },
        composeContentBlocks() {
            let contentBlocksProp = []

            const contentInhoudDescriptions = {
                intro: this.contentData?.content[0].introHtml,
                description: this.contentData?.content[0].descriptionHtml
            }

            this.contentData?.content[0].blocks.forEach((contentBlock) => {
                const setContentBlock = {
                    heading: contentBlock.heading,
                    body: contentBlock.bodyHtml,
                    displayValue: contentBlock.displayValue ? contentBlock.displayValue : contentBlock.typeDisplayValue
                }

                contentBlocksProp = [...contentBlocksProp, setContentBlock]
            })

            this.setPreviewContentBlocks = {
                contentInhoudDescriptions,
                contentBlocksProp
            }
        },
        triggerToastMessage(type, message) {
            const toastNotification = {
                type,
                message
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })
        },
        closeInhoudPreviewPopup() {
            this.isInhoudPreviewPopupVisible = false
        },

        openInhoudPreviewPopup() {
            this.$v.$touch()

            this.composeContentBlocks()

            if (this.$v.$invalid || this.$v.$error) {
                this.triggerToastMessage('error', missingMandatoryFieldsError)

                return
            }
            this.isInhoudPreviewPopupVisible = true
        },

        addContentBlockHandler() {
            this.$v.$touch()
            this.composeContentBlocks()

            const checkForEmpty = this.setPreviewContentBlocks.contentBlocksProp.some((el) => el.displayValue === undefined)

            if (checkForEmpty || this.$v.contentBlocks.$error) {
                this.triggerToastMessage('error', addNewContentBlockError)

                return
            }

            const emptyContentBlock = {
                heading: '',
                type: '',
                body: '',
                bodyHtml: ''
            }

            addItem(this.contentBlocks, 'heading', emptyContentBlock)
        },

        removeContentBlock(index) {
            this.contentBlocks.splice(index, 1)
        },
        updateEditedCourse() {
            this.$v.$touch()

            if (!this.$v.$invalid) {
                this.$emit('submitForm')
            } else {
                this.emitValidation()
            }
        },
        async getCourseContentBlockTypeEnum() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'contentBlockType',
                    null,
                    null,
                    false
                )
                if (!response) return

                this.courseContentBlockTypes = response

            } catch (error) {
                console.error('Something went wrong while retrieving enum list', error)
            }
        },
        headingError(index) {
            return isRequiredErrorMessage('heading', 'Titel', this.$v.contentBlocks.$each[index]?.heading)
        },
        typeError(index) {
            return isRequiredErrorMessage('type', 'Type', this.$v.contentBlocks.$each[index]?.type)
        },
        bodyHtmlError(index) {
            return isRequiredErrorMessage('bodyHtml', 'Inhoud', this.$v.contentBlocks.$each[index]?.bodyHtml)
        },
        isContentBlockRequired(contentBlock) {
            return (contentBlock.bodyHtml !== '' || contentBlock.body !== '' || contentBlock.type !== '' || contentBlock.heading !== '')
        },
        contentBlockTypeHandler(index, newValue) {
            this.setContentBlockType({ index, type: newValue })

            this.$v.contentBlocks.$each[index].type.$touch()
        },
        introChange(value) {
            this.contentData.content[0].intro = value;
        },
        introHtmlChange(value) {
            this.contentData.content[0].introHtml = value;
        },
        descriptionChange(value){
            this.contentData.content[0].description = value;
        },
        descriptionHtmlChange(value){
            this.contentData.content[0].descriptionHtml = value;
        },
        setBlockBodyContent(index, value) {
            this.contentBlocks[index].body = value;
        },
        setBlockBodyHtmlContent(index, value) {
            this.contentBlocks[index].bodyHtml = value;
        },
        setBlockContent(key, value, index) {
            this.contentBlocks[index][key] = value
            const getDisplayValue = this.courseContentBlockTypes.find((item) => item.value === value)

            if (getDisplayValue !== undefined) {
                this.contentBlocks[index].displayValue = getDisplayValue.displayText

            }
        }
    }
}
</script>